import * as React from 'react'
import Layout from '../../components/Layout'
import banner from '../../images/news/MBRSC/banner.jpg'
import img_1_0 from '../../images/news/MBRSC/img_1_0.jpg'
import img_1_1 from '../../images/news/MBRSC/img_1_1.jpg'
import img_1_2 from '../../images/news/MBRSC/img_1_2.jpg'


const lorem = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. "

export default function PostPage() {
    return (
        <Layout title='MBRSC Visit | Conex Research'>
            <section className='w-screen h-fit mt-6 mb-20'>

                <div className='px-8 lg:px-20'>

                    <h5 className='mb-10 sm:hidden'><a href='/media'>{'< '}Back to News</a></h5>
                    <h3 className='text-xl ws:text-3xl font-light text-slate-700 mb-3'>1 Nov 2021</h3>
                    <h1 className='text-5xl sm:text-7xl ws:text-8xl font-semibold mb-3'>Our visit to the MBRSC</h1>
                    <h2 className='text-xl ws:text-3xl sm:text-2xl mb-3'>Conex learns more about the UAE Space Agency's work</h2>

                    <div className='grid grid-rows-1 grid-cols-2'>

                        <h3 className='font-light text-slate-700 justify-self-start ws:text-2xl'>By <a className='text-conex-blue' href='https://twitter.com/alicenewspace' target='_blank'>Alisa Zaripova</a></h3>

                        <div className='w-full flex flex-row justify-end gap-3'>

                            <a href={'https://twitter.com/intent/tweet?url=https%3A%2F%2Fconexresearch.com%2Fnews%2FMBRSC&via=ConexResearch&text=Conex%27s%20Visit%20to%20the%20MBRSC'} title="Share on Twitter" target='_blank' className="flex items-center p-1">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5 text-conex-blue">
                                    <path d="M23.954 4.569a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.061a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.937 4.937 0 004.604 3.417 9.868 9.868 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 002.46-2.548l-.047-.02z"></path>
                                </svg>
                            </a>

                        </div>
                        
                    </div>

                </div>

                <div className='w-full h-fit mt-20'>
                    <img src={banner} className='h-full w-full'/>
                </div>

            </section>

            <section>

                <p className='font-light text-lg ws:text-3xl leading-relaxed px-8 lg:px-64'>
                    <strong className='font-semibold'>Background</strong><br/>

                    During IAC 2021, the Сonex team was lucky to visit the MBR Space Center. The center was originally established in 2006 as the Emirates Institution for Advanced Science and Technology (EIAST), accumulating knowledge, recruiting engineers, and launching advanced
                    scientific projects, until the MBRSC was established in 2015 by Sheikh Mohammed bin Rashid. 

                    <br/><br/><strong className='font-semibold'>The Journey</strong><br/>

                    The team traveled by bus to the space center, which was situated along the outskirts of Dubai. As we approached, we began to recognize the space center by the towering antennas used to communicate with the countries spacecraft. When we got off the buses we were divided into several groups and invited into a building. "Positivity is a way of thinking, and happiness is a lifestyle. In other words, it’s not what you own or do that makes you happy, it is how you think about things." - H.H. Sheikh Mohammed Bin Rashid Al Maktoum, Vice President and Prime Minister of UAE, and Ruler of Dubai. This was the inscription that greeted us in the visitor lobby.

                    <br/><br/><strong className='font-semibold'>The Space Center</strong><br/>

                    The space center consists of some service buildings, separated from each other by flowering, evergreen gardens. It was an incredibly beautiful and atmospheric place. The space center itself was quite intimate, the buildings were ladened with futuristic production, assembly, and science clean rooms and laboratories accessed by a cladded, minimalist corridor offering expansive views of the upcoming technology being developed within the facility. As of today, the space center employs more than 200 people, 40% of whom are women. There are four main programs at the space center:

                    <br/><br/>
                    - Satellite Development Programme<br/>
                    - Emirates Mars Mission<br/>
                    - Mars 2117<br/>
                    - UAE Astronaut Programme
                    <br/><br/>

                    As we ventured through the facility we saw the laboratories developing satellites such as KhalifaSat, Nayif-1 CubeSat in cooperation with AUS, and DMSat-1 - the first environmental Nano-Satellite in the Arab world devoted to aiding the protection of the environment. Throughout the trip, one room was of the greatest interest to all who were present and that was the mission operations center. It has a very unique and historical record, being the control center for the United Arab Emirates national technological breakthrough, launching to Mars the 'Hope Probe'. We were able to see behind the modernistic control stands and reminisce the great achievements of the UAE's space exploration. 

                </p>

                <div className='flex flex-col md:flex-row gap-10 md:gap-10 lg:gap-20 p-10 md:p-8 lg:p-16 basis-1/3'>
                    <div>
                        <img src={img_1_0} className='rounded-xl'/>
                    </div>
                    <div>
                        <img src={img_1_1} className=' rounded-xl'/>
                    </div>
                    <div>
                        <img src={img_1_2} className='rounded-xl'/>
                    </div>
                </div>

                <p className='font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64'>
                    <strong className='font-semibold'>The Hope Probe</strong><br/>

                    Hope is building a complete picture of the Martian atmosphere and studying how Mars’ climate changes over time. This will give scientists deeper insight into ancient Mars and whether the planet could have once supported life. It will also help us understand how our own planet’s climate is changing, and what the consequences of those changes are. For example, the United Arab Emirates' Hope spacecraft captured an incredible and very clear image of Mars during its arrival in February 2021. Hope was roughly 25,000 kilometers (15,500 miles) above the surface at the time.

                    This mission is also a direct demonstration of the importance of international cooperation for the success of humanity's space exploration. The mission was developed with the participation of scientists and engineers from U.S. universities (the University of Colorado at Boulder, the University of Arizona, and the University of California at Berkeley - they mainly built scientific instruments for the spacecraft), the launch was fully supported by Japan and took place on a Japanese rocket H-IIA. “Space exploration brings us all together, and when more countries participate and cooperate, everyone wins.” That's why the Conex team is very multinational and spans six continents around the globe. 

                    <br/><br/><strong className='font-semibold'>Going Forward</strong><br/>

                    The country has big plans for more space exploration, such as sending the first-ever female Emirati astronaut into space. Nora Al Matrooshi was chosen for this honorable historic mission. Also, the UAE is planning to conquer the red planet not only by spacecraft but also by sending humans there. Mars 2117 Programme aims to establish the first inhabitable human settlement on Mars by 2117. 

                    <br/><br/><strong className='font-semibold'>Reflection</strong><br/>

                    We were happy to be in a place with the spirit of new victories and enjoyed the excursion during the IAC.  The friendliness and passion of the people who worked at the space center were an inspiration to see. The Conex team had an invaluable experience, and are very grateful to all the organizers of the trip, the MBR Space Center officers, and the organizers of IAC 2021.
                                        
                </p>

            </section>

        </Layout>
    )
}
